jQuery(document).ready(function () {
  jQuery(".accordion-content").on("show.bs.collapse", function () {
    // var id = jQuery(this).attr("data-service-id");

    // Hide all asides
    //jQuery(".card-aside").removeClass('inplace');

    // Show this aside
    //jQuery("#card-aside-" + id).addClass('inplace');

    var element_id = jQuery(this).attr("id");
    console.log(element_id);
    // This accordion
    var this_accordion_id = jQuery(this).attr("data-parent"); // has hash

    // Fidn the parent card and add active to it.
    jQuery(this_accordion_id + " .card").removeClass("active");
    jQuery(this_accordion_id + " .card").removeClass("inplace");
    jQuery("#" + element_id)
      .parent()
      .parent()
      .addClass("active");

    // Add inplace after .2 seconds
    setTimeout(function () {
      jQuery("#" + element_id)
        .parent()
        .parent()
        .addClass("inplace");
    }, 220);

  });

  // Add data-featherlight="image" to the first link in each grid item.

  jQuery(".lightbox-grid").each(function (index, element) {
    jQuery(element)
      .find(".grid-item")
      .each(function (index2, element2) {
        // Do something with each inner element
        jQuery(element2).find('a').first().attr('data-featherlight', 'image');
      });
  });
});
